<template>
  <c-box
    p="6"
    bg="#fff"
    borderRadius="8px"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
    minHeight="200px"
  >
    <c-heading fontSize="2xl" fontWeight="600" mb="3"> Milestones </c-heading>
    <c-flex
      :direction="{ base: 'row', md: 'row', xs: 'column' }"
      justify="space-around"
      align="center"
    >
      <!-- <c-flex align="center" mr="auto">
        <svg
          fill="#798892"
          v-chakra="{
            width: '16px',
            height: '16px',
            mr: 3,
          }"
        >
          <use href="@/assets/icons/chevron-left.svg#chevron"></use>
        </svg>
        <c-text fontSize="4xl" fontWeight="700">2021</c-text>
        <svg
          fill="#ef5323"
          v-chakra="{
            width: '16px',
            height: '16px',
            ml: 3,
          }"
        >
          <use href="@/assets/icons/chevron-right.svg#chevron"></use>
        </svg>
      </c-flex> -->
      <c-flex
        :direction="{ base: 'row', md: 'row', xs: 'column' }"
        align="center"
      >
        <c-flex :alignItems="{ base: 'baseline', md: 'center', xs: 'center' }">
          <c-text color="#1A86D0" fontSize="6xl" fontWeight="600" mr="1">{{
            milestonesCount
          }}</c-text>
          <c-text fontWeight="600">Milestones</c-text>
        </c-flex>
        <c-box :mr="{ base: '2', md: '2', xs: '0' }">
          <highcharts :options="chartOptions"></highcharts>
        </c-box>
        <c-stack :spacing="2">
          <c-stack
            :spacing="1"
            :textAlign="{ base: 'left', md: 'left', xs: 'center' }"
          >
            <c-text
              color="#EF5323"
              fontSize="md"
              fontWeight="600"
              :mr="{ base: '1', md: '1', xs: '0' }"
              >{{ inProgressMilestonesCount }}</c-text
            >
            <c-text fontSize="sm">In Progress</c-text>
          </c-stack>
          <c-stack
            :spacing="1"
            :textAlign="{ base: 'left', md: 'left', xs: 'center' }"
          >
            <c-text
              color="#0FC694"
              fontSize="md"
              fontWeight="600"
              :mr="{ base: '1', md: '1', xs: '0' }"
            >
              {{ acheivedMilestonesCount }}</c-text
            >
            <c-text fontSize="sm">Acheived</c-text>
          </c-stack>
        </c-stack>
      </c-flex>
      <c-flex
        :direction="{ base: 'row', md: 'row', xs: 'column' }"
        :align="{ base: 'baseline', md: 'center', xs: 'center' }"
      >
        <c-text
          color="#1A86D0"
          fontSize="6xl"
          fontWeight="600"
          :mr="{ base: '1', md: '1', xs: '0' }"
          >{{ phasesCount }}</c-text
        >
        <c-text fontWeight="600">Phases</c-text>
      </c-flex>
    </c-flex>
    <!-- <c-flex my="12" justify="center">
      <c-flex ml="auto">
        <AppSelect
          color="dark"
          :label="'label'"
          :reduce="(type) => type.value"
          :options="milestoneTypes"
          v-model="filters.milestoneType"
          indicator-fill="orange"
          v-chakra="{
            mr: '6',
          }"
        />
        <AppSelect
          color="dark"
          :options="['All Categories']"
          value="All Categories"
          indicator-fill="orange"
        />
      </c-flex>
      <c-popover placement="bottom-start" v-slot="{ onClose }">
        <c-popover-trigger>
          <c-link
            color="vc-orange.400"
            display="flex"
            alignItems="center"
            ml="auto"
          >
            <c-text mr="2" fontWeight="600"> Add </c-text>
            <svg
              fill="#ef5323"
              v-chakra="{
                width: '24px',
                height: '24px',
              }"
            >
              <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
            </svg>
          </c-link>
        </c-popover-trigger>
        <c-popover-content w="max-content">
          <c-popover-body p="0">
            <c-list>
              <c-list-item borderBottomWidth="1px" fontSize="sm">
                <c-box
                  w="100%"
                  h="100%"
                  px="3"
                  py="2"
                  cursor="pointer"
                  @click="addTimelineItem('milestone', onClose)"
                >
                  Milestone
                </c-box>
              </c-list-item>
              <c-list-item fontSize="sm">
                <c-box
                  w="100%"
                  h="100%"
                  px="3"
                  py="2"
                  cursor="pointer"
                  @click="addTimelineItem('phase', onClose)"
                >
                  Phase
                </c-box>
              </c-list-item>
            </c-list>
          </c-popover-body>
        </c-popover-content>
      </c-popover>
    </c-flex> -->
    <c-box mb="4" overflowX="auto">
      <table class="timeline__table">
        <thead class="timeline__table__header">
          <tr class="timeline__table__header__row">
            <th
              class="timeline__table__header__cell"
              v-for="column in columns"
              :key="column.key"
            >
              {{ column.title }}
            </th>
          </tr>
        </thead>
        <tbody class="timeline__table__body">
          <tr v-for="(data, index) in timelineData" :key="index">
            <td class="timeline__table__body__cell">
              <c-text fontWeight="600" pt="2">
                {{ data.title }}
              </c-text>
            </td>
            <td class="timeline__table__body__cell">
              <c-text color="gray.500" pt="2" fontSize="xs" px="2">
                {{ data.description }}
              </c-text>
            </td>
            <td class="timeline__table__body__cell">
              <c-text pt="2" fontSize="xs" px="2" fontWeight="500">
                {{ data.calender_category ? data.calender_category.title : '' }}
              </c-text>
            </td>
            <td class="timeline__table__body__cell">
              <c-text fontWeight="600" pt="2" fontSize="sm" px="2">
                {{ (data.date || data.from) | moment('DD.MM.YY') }}
              </c-text>
            </td>
            <td class="timeline__table__body__cell">
              <c-flex
                ml="2"
                mt="2"
                :bg="data.scenario ? data.scenario.color : '#2d2d2d'"
                p="2"
                fontSize="sm"
                align="center"
                color="#fff"
                justify="center"
                borderRadius="4px"
                textTransform="capitalize"
                width="max-content"
                minWidth="100px"
              >
                {{ data.scenario ? data.scenario.title : 'Default' }}
              </c-flex>
            </td>
            <td class="timeline__table__body__cell">
              <c-flex p="2" justify="space-between">
                <template v-if="data.status">
                  <c-link
                    v-if="data.status === 'IN_PROGRESS'"
                    display="flex"
                    @click="setAsAcheived(data)"
                    w="max-content"
                    alignItems="center"
                  >
                    <c-text
                      mr="2"
                      fontSize="sm"
                      color="vc-orange.400"
                      fontWeight="600"
                    >
                      IN PROGRESS
                    </c-text>
                    <svg
                      v-chakra="{
                        width: '14px',
                        height: '14px',
                      }"
                    >
                      <use href="@/assets/icons/circle.svg#circle"></use>
                    </svg>
                  </c-link>
                  <c-flex v-else w="max-content" align="center">
                    <c-text
                      mr="2"
                      fontSize="sm"
                      color="#0FC694"
                      fontWeight="600"
                    >
                      ACHIEVED
                    </c-text>
                    <svg
                      v-chakra="{
                        width: '14px',
                        height: '14px',
                      }"
                    >
                      <use href="@/assets/icons/check-circle.svg#check"></use>
                    </svg>
                  </c-flex>
                </template>
                <c-popover
                  placement="bottom"
                  v-if="!data.from"
                  v-slot="{ onClose }"
                >
                  <c-popover-trigger>
                    <c-link>
                      <svg
                        v-chakra="{
                          fill: 'gray.500',
                          width: '14px',
                          height: '14px',
                        }"
                      >
                        <use
                          href="@/assets/icons/dots-horizontal.svg#dots"
                        ></use>
                      </svg>
                    </c-link>
                  </c-popover-trigger>
                  <c-popover-content w="max-content">
                    <c-popover-body p="0">
                      <c-list w="100px">
                        <c-list-item fontSize="sm">
                          <c-pseudo-box
                            py="2"
                            px="3"
                            cursor="pointer"
                            @click="onMilestoneEditClick(data, onClose)"
                          >
                            Edit
                          </c-pseudo-box>
                        </c-list-item>
                        <!-- borderBottomWidth="1px" -->
                        <!-- <c-list-item
                          py="2"
                          px="3"
                          color="vc-orange.400"
                          fontSize="sm"
                          >Delete</c-list-item
                        > -->
                      </c-list>
                    </c-popover-body>
                  </c-popover-content>
                </c-popover>
              </c-flex>
            </td>
          </tr>
        </tbody>
      </table>
    </c-box>
  </c-box>
</template>

<script>
import { mapGetters } from 'vuex';

import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import variablePieInit from 'highcharts/modules/variable-pie';
// import AppSelect from '../../components/AppSelect.vue';

import timelineMixin from '@/mixins/timeline.js';

variablePieInit(Highcharts);

export default {
  mixins: [timelineMixin],
  components: {
    highcharts: Chart,
    // AppSelect,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'variablepie',
          height: 100,
          width: 200,
        },
        title: {
          text: null,
        },
        // tooltip: { enabled: false },
        plotOptions: {
          variablepie: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            minPointSize: 10,
            innerSize: '30%',
            zMin: 0,
            name: 'Milstones',
            size: 100,
            data: [
              {
                name: 'In Progress',
                y: 0,
                z: 0,
                color: '#EF5323',
              },
              {
                name: 'Acheived',
                y: 0,
                z: 0,
                color: '#0FC694',
              },
            ],
          },
        ],
        exporting: { enabled: false },
      },
      columns: [
        { title: 'Title', key: 'title' },
        { title: 'Description', key: 'description' },
        { title: 'Category', key: 'categories' },
        { title: 'Date', key: 'date' },
        { title: 'Scenario', key: 'scenario' },
        { title: 'Status', key: 'status' },
      ],
    };
  },
  methods: {
    addTimelineItem(type, closePopover) {
      this.$emit('addItem', type);
      closePopover();
    },
    setChartValues() {
      this.chartOptions.series[0].data[0].y = this.inProgressMilestonesCount;
      this.chartOptions.series[0].data[0].z = this.inProgressMilestonesCount;
      this.chartOptions.series[0].data[1].y = this.acheivedMilestonesCount;
      this.chartOptions.series[0].data[1].z = this.acheivedMilestonesCount;
    },
    onMilestoneEditClick(milestone, onClose) {
      onClose();
      this.$emit('editMilestone', { ...milestone });
    },
    setAsAcheived(milestone) {
      this.$emit('setAchieved', { ...milestone });
    },
  },
  mounted() {
    this.setChartValues();
  },
  watch: {
    inProgressMilestonesCount: 'setChartValues',
    acheivedMilestonesCount: 'setChartValues',
  },
  computed: {
    ...mapGetters('timeline', [
      'timelineData',
      'phasesCount',
      'milestonesCount',
      'acheivedMilestonesCount',
      'inProgressMilestonesCount',
    ]),
  },
};
</script>

<style lang="scss">
.timeline {
  &__table {
    @apply w-full;
    &__header {
      &__cell {
        @apply py-3 font-medium;
        color: $color-grey;
        border-width: 1px 1px 1px 0;
        border-color: rgba(112, 112, 112, 0.2);
        min-width: 180px;
        max-width: 200px;
        &:last-child {
          border-right-width: 0px;
        }
      }
    }
    &__body {
      &__cell {
        @apply align-top;
        border-color: rgba(112, 112, 112, 0.2);
        border-width: 1px 1px 1px 0;
        height: 150px;
      }
    }
  }
}
</style>
