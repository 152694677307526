import { render, staticRenderFns } from "./MilestonesCard.vue?vue&type=template&id=c331d6de"
import script from "./MilestonesCard.vue?vue&type=script&lang=js"
export * from "./MilestonesCard.vue?vue&type=script&lang=js"
import style0 from "./MilestonesCard.vue?vue&type=style&index=0&id=c331d6de&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CStack: require('@chakra-ui/vue').CStack, CLink: require('@chakra-ui/vue').CLink, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover})
