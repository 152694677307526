import cloneDeep from 'lodash.clonedeep';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      filters: {
        milestoneType: 'ALL',
        scenario: 'ALL',
        categoryId: 'ALL',
      },
      milestoneTypes: [
        { label: 'All Milestones', value: 'ALL' },
        { label: 'In Progress', value: 'IN_PROGRESS' },
        { label: 'Achieved', value: 'ACHIEVED' },
      ],
      displayedMilestones: [],
    };
  },
  computed: {
    ...mapState('timeline', {
      categories: (state) => state.categories,
      phases: (state) => state.phases,
      scenarios: (state) => state.scenarios,
      milestones: (state) => state.milestones,
    }),
    categoriesOptions() {
      return [...this.categories, { id: 'ALL', title: 'All Categories' }];
    },
  },
  watch: {
    filters: {
      deep: true,
      immediate: true,
      handler: function () {
        setTimeout(() => {
          this.setDisplayedMilestones();
        }, 1000);
      },
    },
    milestones() {
      this.setDisplayedMilestones();
    },
  },
  methods: {
    setDisplayedMilestones() {
      let milestones = cloneDeep(this.milestones);
      Object.keys(this.filters).map((filter) => {
        switch (filter) {
          case 'milestoneType':
            if (this.filters.milestoneType === 'ALL') {
              milestones = milestones.filter((milestones) => milestones.status);
            } else {
              milestones = milestones.filter(
                (milestones) => milestones.status === this.filters.milestoneType
              );
            }
            break;
          case 'scenario':
            if (this.filters.scenario === 'ALL') {
              milestones = milestones.filter((milestones) => milestones);
            } else {
              milestones = milestones.filter(
                (milestones) => milestones.scenarioId === this.filters.scenario
              );
            }
            break;
          case 'categoryId':
            if (this.filters.categoryId === 'ALL') {
              milestones = milestones.filter((milestones) => milestones);
            } else {
              milestones = milestones.filter(
                (milestones) =>
                  milestones.categoryId === this.filters.categoryId
              );
            }
            break;

          default:
            break;
        }
      });
      this.displayedMilestones = cloneDeep(milestones);
    },
  },
};
