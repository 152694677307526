<template>
  <c-box
    p="6"
    bg="#fff"
    borderRadius="8px"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
    minHeight="200px"
  >
    <c-flex align="center" justify="space-between" mb="6">
      <c-flex align="baseline">
        <c-heading fontSize="2xl" fontWeight="600" mr="3">Timeline</c-heading>
        <c-link
          @click="onFullscreenClick"
          color="vc-orange.400"
          fontSize="sm"
          mr="2"
          fontWeight="500"
          >Full Screen</c-link
        >
      </c-flex>
      <c-popover placement="bottom-start" v-slot="{ onClose }">
        <c-popover-trigger>
          <c-link color="vc-orange.400" display="flex" alignItems="center">
            <c-text mr="2" fontWeight="600">Add</c-text>
            <svg
              fill="#ef5323"
              v-chakra="{
                width: '24px',
                height: '24px',
              }"
            >
              <use href="@/assets/icons/add-circle-line.svg#add-circle" />
            </svg>
          </c-link>
        </c-popover-trigger>
        <c-popover-content w="max-content">
          <c-popover-body p="0">
            <c-list>
              <c-list-item borderBottomWidth="1px" fontSize="sm">
                <c-box
                  w="100%"
                  h="100%"
                  px="3"
                  py="2"
                  cursor="pointer"
                  @click="addTimelineItem('category', onClose)"
                  >Category</c-box
                >
              </c-list-item>
              <c-list-item borderBottomWidth="1px" fontSize="sm">
                <c-box
                  w="100%"
                  h="100%"
                  px="3"
                  py="2"
                  cursor="pointer"
                  @click="addTimelineItem('milestone', onClose)"
                  >Milestone</c-box
                >
              </c-list-item>
              <c-list-item fontSize="sm">
                <c-box
                  w="100%"
                  h="100%"
                  px="3"
                  py="2"
                  cursor="pointer"
                  @click="addTimelineItem('phase', onClose)"
                  >Phase</c-box
                >
              </c-list-item>
            </c-list>
          </c-popover-body>
        </c-popover-content>
      </c-popover>
    </c-flex>
    <c-flex mb="5" align="center">
      <c-flex>
        <c-pseudo-box
          as="a"
          px="3"
          py="2"
          mr="4"
          class="scenario"
          cursor="pointer"
          @click="onScenarioSelect(scenarioIndex)"
          display="flex"
          :key="scenario.id"
          :color="scenario.isActive ? '#fff' : scenario.color"
          :bg="scenario.isActive ? scenario.color : 'transparent'"
          v-for="(scenario, scenarioIndex) in scenariosData"
          fontWeight="500"
          fontSize="xs"
          borderRadius="2px"
          position="relative"
        >
          {{ scenario.title }}
          <svg
            class="scenario__delete"
            @click.stop="onDeleteScenario({ scenario })"
            v-chakra="{
              w: '3',
              h: '3',
              cursor: 'pointer',
              fill: 'vc-orange.400',
              position: 'absolute',
              top: '-4px',
              right: '-1px',
              display: 'none',
            }"
          >
            <use href="@/assets/icons/icon-close-fill.svg#close" />
          </svg>
        </c-pseudo-box>
      </c-flex>
      <c-popover placement="right" v-slot="{ onClose }">
        <c-popover-trigger>
          <c-link
            display="flex"
            alignItems="center"
            color="gray.500"
            fontSize="xs"
            mr="4"
          >
            <c-icon name="add" mr="2" ml="2"></c-icon>Scenario
          </c-link>
        </c-popover-trigger>
        <c-popover-content zIndex="docked" w="200px">
          <c-popover-close-button />
          <c-popover-header fontSize="xs">New Scenario</c-popover-header>
          <c-popover-body>
            <c-stack :spacing="4">
              <c-input
                placeholder="Scenario Name"
                size="sm"
                v-model="$v.scenarioForm.title.$model"
              />
              <c-flex align="center">
                <c-text mr="3">Color:</c-text>
                <v-swatches v-model="$v.scenarioForm.color.$model"></v-swatches>
              </c-flex>
              <c-button
                variant-color="vc-orange"
                size="sm"
                w="120px"
                ml="auto"
                :disabled="$v.scenarioForm.$invalid || isAddingScenario"
                @click="onScenarioSubmit(onClose)"
              >
                Add
                <c-spinner
                  v-if="isAddingScenario"
                  ml="2"
                  color="#fff"
                  thickness="2px"
                />
              </c-button>
            </c-stack>
          </c-popover-body>
        </c-popover-content>
      </c-popover>
      <c-link
        v-if="filters.scenario !== 'ALL'"
        @click="clearScenarioFilter"
        color="red.400"
        fontSize="xs"
        ml="3"
        >Clear filter</c-link
      >

      <AppSegment
        :segments="segments"
        name="view"
        @input="handleChange"
        v-model="currentView"
      />
    </c-flex>
    <c-flex>
      <AppSelect
        color="dark"
        :label="'label'"
        :reduce="(type) => type.value"
        :options="milestoneTypes"
        v-model="filters.milestoneType"
        indicator-fill="orange"
        v-chakra="{
          mr: '6',
        }"
      />
      <AppSelect
        color="dark"
        label="title"
        :reduce="(category) => category.id"
        :options="categoriesOptions"
        v-model="filters.categoryId"
        indicator-fill="orange"
      />
    </c-flex>
    <c-box ref="timelineArea" bg="#fff">
      <c-flex mt="2" w="max-content" borderWidth="1px" px="2" py="2">
        <c-button
          :bg="!(isZoomMode || isDragMode) ? '#000' : 'transparent'"
          @click="setMode({ drag: false, zoom: false })"
          variant-color="black"
          :variant="'outline'"
          size="sm"
          mr="2"
        >
          <svg
            :style="{
              fill: !(isZoomMode || isDragMode) ? '#fff' : '#000',
            }"
            v-chakra="{
              width: '6',
              height: '6',
              fill: '#000',
            }"
          >
            <use href="@/assets/icons/icon-pointer.svg#pointer" />
          </svg>
        </c-button>
        <c-button
          :bg="isZoomMode ? '#000' : 'transparent'"
          @click="setMode({ drag: false, zoom: true })"
          variant-color="black"
          :variant="'outline'"
          size="sm"
          mr="2"
        >
          <svg
            :style="{
              fill: isZoomMode ? '#fff' : '#000',
            }"
            v-chakra="{
              width: '6',
              height: '6',
              fill: '#000',
            }"
          >
            <use href="@/assets/icons/icon-zoom-option.svg#zoom" />
          </svg>
        </c-button>
        <c-button
          :bg="isDragMode ? '#000' : 'transparent'"
          @click="setMode({ drag: true, zoom: false })"
          variant-color="black"
          :variant="'outline'"
          size="sm"
        >
          <svg
            :style="{
              fill: isDragMode ? '#fff' : '#000',
            }"
            v-chakra="{
              width: '6',
              height: '6',
              fill: '#000',
            }"
          >
            <use href="@/assets/icons/icon-drag.svg#drag" />
          </svg>
        </c-button>
      </c-flex>

      <c-box
        mt="2"
        class="milestones-container"
        overflowX="scroll"
        borderWidth="1px"
      >
        <c-flex
          direction="column"
          width="100%"
          position="relative"
          ref="timeline"
          bg="#fff"
          class="timeline"
        >
          <c-flex
            v-if="currentView == 'milestone'"
            class="milestones"
            minHeight="500px"
            w="100%"
            position="relative"
            :h="milestoneAreaHeight + 30"
            align="center"
          >
            <template v-for="day in days">
              <c-box
                display="flex"
                flex="0 0 250px"
                flexDirection="column"
                minHeight="100%"
                w="250px"
                position="relative"
                mr="2"
                v-if="day.milestones.length || day.isToday"
                :key="day.date.toISOString()"
              >
                <c-flex
                  class="todayMarker"
                  py="4"
                  direction="column"
                  align="center"
                  v-if="day.isToday"
                >
                  <h1>Today</h1>

                  <c-box
                    :h="milestoneAreaHeight + 10"
                    w="1px"
                    bg="black"
                  ></c-box>
                </c-flex>
                <div v-else>
                  <c-box
                    position="absolute"
                    :bottom="milestone.isEven ? `` : '50%'"
                    :top="milestone.isEven ? '50%' : ``"
                    :left="milestone.isEven ? '30px' : '0'"
                    w="100%"
                    :h="milestone.height + 'px'"
                    display="flex"
                    flexDirection="column"
                    v-for="(milestone, index) in day.milestones"
                    :key="milestone.id"
                    :zIndex="day.milestones.length - index"
                    ref="milestonesRef"
                  >
                    <c-pseudo-box
                      bordercolor="vc-orange.400"
                      borderWidth="1px"
                      p="3"
                      borderRadius="4px"
                      w="200px"
                      position="relative"
                      alignSelf="center"
                      :order="milestone.isEven ? '2' : '1'"
                      bg="#fff"
                      :_after="{
                        content: afterContent,
                        w: '10px',
                        h: '10px',
                        bg: '#fff',
                        left: '50%',
                        bottom: milestone.isEven ? '94%' : '-6px',
                        transform: milestone.isEven
                          ? 'translateX(-50%) rotateZ(315deg)'
                          : 'translateX(-50%) rotateZ(136deg)',
                        position: 'absolute',
                        borderTopWidth: '1px',
                        borderRightWidth: '1px',
                        borderColor: 'vc-orange.400',
                      }"
                    >
                      <c-flex justify="space-between" mb="2">
                        <c-heading fontSize="10px" fontWeight="500">
                          {{
                            milestone.calender_category
                              ? milestone.calender_category.title
                              : ''
                          }}
                        </c-heading>
                        <c-popover
                          use-portal
                          placement="bottom"
                          v-chakra
                          v-slot="{ onClose }"
                        >
                          <c-popover-trigger>
                            <c-link>
                              <svg
                                fill="#ef5323"
                                v-chakra="{
                                  width: '14px',
                                  height: '14px',
                                }"
                              >
                                <use
                                  href="@/assets/icons/dots-horizontal.svg#dots"
                                />
                              </svg>
                            </c-link>
                          </c-popover-trigger>
                          <c-popover-content w="max-content" z-index="40">
                            <c-popover-body p="0" bg="white">
                              <c-list>
                                <c-list-item>
                                  <c-pseudo-box
                                    cursor="pointer"
                                    as="a"
                                    display="flex"
                                    px="2"
                                    py="3"
                                    @click="
                                      onMilestoneEditClick(milestone, onClose)
                                    "
                                    >Edit Milestone</c-pseudo-box
                                  >
                                </c-list-item>
                                <c-list-item
                                  v-if="milestone.status === 'IN_PROGRESS'"
                                  borderBottomWidth="1px"
                                >
                                  <c-box
                                    cursor="pointer"
                                    w="100%"
                                    px="2"
                                    py="2"
                                    @click="setAsAcheived(milestone)"
                                    >Mark as acheived</c-box
                                  >
                                </c-list-item>
                                <c-list-item
                                  v-if="milestone.status !== 'IN_PROGRESS'"
                                  borderBottomWidth="1px"
                                >
                                  <c-box
                                    cursor="pointer"
                                    w="100%"
                                    px="2"
                                    py="2"
                                    @click="setAsInProgress(milestone)"
                                    >Mark as In Progress</c-box
                                  >
                                </c-list-item>
                                <c-list-item>
                                  <c-pseudo-box
                                    cursor="pointer"
                                    as="a"
                                    display="flex"
                                    px="2"
                                    py="3"
                                    @click="
                                      onMilestoneDelete(milestone, onClose)
                                    "
                                    >Delete Milestone</c-pseudo-box
                                  >
                                </c-list-item>
                              </c-list>
                            </c-popover-body>
                          </c-popover-content>
                        </c-popover>
                      </c-flex>
                      <c-popover trigger="hover" placement="top-end">
                        <c-popover-trigger>
                          <c-heading
                            maxWidth="100%"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            fontSize="sm"
                            fontWeight="600"
                            >{{ milestone.title }}</c-heading
                          >
                        </c-popover-trigger>
                        <c-popover-content>
                          <c-popover-arrow />
                          <c-popover-body zIndex="modal">
                            <c-text fontSize="sm" overflowWrap="break-word">
                              {{ milestone.title }}
                            </c-text>
                          </c-popover-body>
                        </c-popover-content>
                      </c-popover>
                      <c-flex justify="space-between" mt="2">
                        <c-text
                          color="gray.500"
                          fontSize="10px"
                          fontWeight="500"
                          >{{ milestone.date | moment('DD, MMMM') }}</c-text
                        >
                        <c-link
                          color="vc-orange.400"
                          fontSize="10px"
                          display="flex"
                          fontWeight="500"
                          alignItems="center"
                          v-if="milestone.status === 'IN_PROGRESS'"
                          @click="setAsAcheived(milestone)"
                        >
                          In Progress
                          <svg
                            fill="#ef5323"
                            v-chakra="{
                              width: '14px',
                              height: '14px',
                              ml: '2',
                            }"
                          >
                            <use href="@/assets/icons/circle.svg#circle" />
                          </svg>
                        </c-link>

                        <c-link
                          color="#0FC694"
                          fontSize="10px"
                          display="flex"
                          fontWeight="500"
                          alignItems="center"
                          v-else
                          @click="setAsInProgress(milestone)"
                        >
                          Acheived
                          <svg
                            v-chakra="{
                              width: '14px',
                              height: '14px',
                              ml: '2',
                            }"
                          >
                            <use href="@/assets/icons/check-circle.svg#check" />
                          </svg>
                        </c-link>
                      </c-flex>
                      <c-flex mt="1" v-if="milestone.status === 'ACHIEVED'">
                        <c-text fontSize="10px">
                          Achieved on:
                          {{ milestone.dateAchieved | moment('DD MMM.') }}
                        </c-text>
                      </c-flex>
                    </c-pseudo-box>
                    <c-box
                      w="50.5%"
                      h="100%"
                      borderRight="2px dotted"
                      bordercolor="vc-orange.400"
                      :order="milestone.isEven ? '1' : '2'"
                    ></c-box>
                    <c-flex
                      w="4"
                      h="4"
                      bg="#fff"
                      align="center"
                      justify="center"
                      left="50%"
                      :bottom="milestone.isEven ? 'calc(100% - 8px)' : '-8px'"
                      position="absolute"
                      transform="translateX(-50%)"
                      borderRadius="50%"
                      borderWidth="1px"
                      bordercolor="#D58637"
                      zIndex="1"
                    >
                      <c-box
                        w="8px"
                        h="8px"
                        bg="vc-orange.400"
                        borderRadius="50%"
                      ></c-box>
                    </c-flex>
                  </c-box>
                </div>
              </c-box>
            </template>
            <c-flex
              direction="column"
              align="center"
              justify="center"
              position="absolute"
              transform="translateY(-50%)"
              top="50%"
              left="0"
              right="0"
              w="100%"
              ref="line"
            >
              <c-box h="2px" bg="#2d2d2d" w="100%" my="2"></c-box>
            </c-flex>
          </c-flex>
          <c-flex v-else>
            <template v-for="day in days">
              <c-box
                display="flex"
                flex="0 0 250px"
                flexDirection="column"
                w="250px"
                h="max-content"
                position="relative"
                v-if="day.isPhaseDate"
                :key="day.date.toISOString() + '2'"
              >
                <c-flex
                  direction="column"
                  justify="flex-end"
                  mt="auto"
                  w="100%"
                >
                  <c-box
                    w="100%"
                    h="30px"
                    mb="3"
                    px="3"
                    bg="#1A86D0"
                    color="#fff"
                    :mr="phase.isLast ? '4' : '0'"
                    :ml="phase.isFirst ? '4' : '0'"
                    v-for="phase in day.phases"
                    :key="phase.id"
                    :borderTopLeftRadius="phase.isFirst ? '4px' : '0'"
                    :borderBottomLeftRadius="phase.isFirst ? '4px' : '0'"
                    :borderTopRightRadius="phase.isLast ? '4px' : '0'"
                    :borderBottomRightRadius="phase.isLast ? '4px' : '0'"
                    @click="addTimelineItem('phase', onClose, phase)"
                  >
                    <c-flex h="100%" align="center">
                      <c-text
                        mr="6"
                        v-if="phase.isFirst"
                        fontSize="xs"
                        fontWeight="300"
                        >{{ phase.from | moment('DD, MMMM') }}</c-text
                      >
                      <c-text
                        v-if="phase.isMiddle"
                        fontSize="sm"
                        fontWeight="600"
                        whiteSpace="nowrap"
                        >{{ phase.title }}</c-text
                      >
                      <c-text
                        ml="auto"
                        v-if="phase.isLast"
                        fontSize="xs"
                        fontWeight="300"
                        >{{ phase.to | moment('DD.MM.YYYY') }}</c-text
                      >
                    </c-flex>
                  </c-box>
                </c-flex>
              </c-box>
            </template>
          </c-flex>
        </c-flex>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import VSwatches from 'vue-swatches';

import { required } from 'vuelidate/lib/validators';

import AppSelect from './../../components/AppSelect';
import AppSegment from '../../GrowthFramework/components/AppSegment.vue';
import { enumerateDaysBetweenDates } from '@/helpers/utils';
import { generateID } from '@/helpers/data';
import { addScenario, updateScenario } from '@/services/timeline';

import timelineMixin from '@/mixins/timeline.js';

import { renderer } from '@/helpers/renderer.js';

export default {
  mixins: [timelineMixin],
  components: {
    AppSelect,
    VSwatches,
    AppSegment,
  },
  validations: {
    scenarioForm: {
      title: {
        required,
      },
      color: {
        required,
      },
    },
  },
  data() {
    return {
      segments: [
        { title: 'Milestones', value: 'milestone' },
        { title: 'Phases', value: 'phases' },
      ],
      currentView: 'milestone',
      afterContent: '" "',
      days: [],
      scenarioForm: {
        title: '',
        color: '',
      },
      scenariosData: [],
      isAddingScenario: false,
      isMouseDown: false,
      isDragMode: false,
      isZoomMode: false,
      milestoneAreaHeight: 500,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
  },
  created() {
    this.setTimeline();
  },
  async mounted() {
    this.$refs['line'].$el.style.width =
      this.$refs['timeline'].$el.scrollWidth + 'px';
    this.setupZoomAndPan();
    this.setScenariosData().then(() => {
      setTimeout(() => {
        this.scrollToTodayMarker();
      }, 1000);
    });
  },
  watch: {
    phases() {
      this.setTimeline();
    },
    displayedMilestones() {
      this.setTimeline();
    },
    scenarios: {
      immediate: true,
      handler: function () {
        this.setScenariosData();
      },
    },
  },
  methods: {
    ...mapMutations({
      addScenario: 'timeline/addScenario',
      deleteScenario: 'timeline/deleteScenario',
      replaceScenario: 'timeline/replaceScenario',
    }),
    isToday(date) {
      this.$moment(date).isSame(new Date(), 'day');
    },
    isBetween(from, to) {
      let today = this.$moment().format('YYYY-MM-DD');
      let afterFromDate = this.$moment(today).isSameOrAfter(from);
      let beforeToDate = this.$moment(today).isSameOrBefore(to);
      if (afterFromDate && beforeToDate) {
        return true;
      }
      return false;
    },
    hasTodayInRange() {
      const { minPhaseDate, maxPhaseDate } = this.getPhaseDates();
      const { minDaysDate, maxDaysDate } = this.getMilestoneDates();
      const minDate = this.$moment.min([minPhaseDate, minDaysDate]);
      const maxDate = this.$moment.max([maxPhaseDate, maxDaysDate]);

      return this.isBetween(minDate, maxDate);
    },
    setTimeline() {
      const { minPhaseDate, maxPhaseDate } = this.getPhaseDates();
      const { minDaysDate, maxDaysDate } = this.getMilestoneDates();
      const minDate = this.$moment.min([minPhaseDate, minDaysDate]);
      const maxDate = this.$moment.max([maxPhaseDate, maxDaysDate]);
      const daysBetweenDates = enumerateDaysBetweenDates(minDate, maxDate);

      this.days = daysBetweenDates
        .map((date) => {
          return {
            date: date.clone().toDate(),
            isToday: date.isSame(new Date(), 'day'),
            milestones: this.displayedMilestones
              .filter((milestone) => date.isSame(milestone.date, 'day'))
              .map((milestone, index) => {
                return {
                  ...milestone,
                  isEven: (index + 1) % 2 == 0,
                  index: index + 1,
                };
              })
              .map((milestone, index, milestones) => {
                return {
                  ...milestone,
                  height: this.getMilestoneHeight(
                    milestone.index,
                    milestone.isEven
                      ? milestones.filter((milestone) => milestone.isEven)
                      : milestones.filter((milestone) => !milestone.isEven)
                  ),
                };
              }),
            phases: this.phases
              .filter((phase) =>
                date.isBetween(phase.from, phase.to, undefined, '[]')
              )
              .map((phase) => {
                const middle = new Date(
                  (new Date(phase.from).getTime() +
                    new Date(phase.to).getTime()) /
                    2
                );
                return {
                  ...phase,
                  isFirst: date.isSame(this.$moment(phase.from)),
                  isLast: date.isSame(phase.to),
                  isMiddle: date.isSame(middle, 'day'),
                  id: generateID(5),
                };
              })
              .sort((a, b) => this.$moment(a.from).diff(this.$moment(b.from))),
          };
        })
        .map((day) => {
          return {
            ...day,
            isPhaseDate: day.phases.some(
              (phase) => phase.isFirst || phase.isLast || phase.isMiddle
            ),
          };
        })
        .sort((a, b) => {
          return a.date - b.date;
        });

      this.$nextTick(() => {
        this.$refs['line'].$el.style.width =
          this.$refs['timeline'].$el.scrollWidth + 'px';
        if (this.$refs.milestonesRef) {
          this.milestoneAreaHeight =
            Math.max(
              ...this.$refs.milestonesRef.map((ref) => ref.$el.clientHeight)
            ) * 2;
        }
      });
    },
    async onScenarioSubmit(closePopover) {
      this.isAddingScenario = true;
      try {
        const res = await addScenario({
          ...this.scenarioForm,
          companyId: this.activeCompany.id,
        });
        this.addScenario(res.data.insert_scenario_one);
        this.isAddingScenario = false;
        closePopover();
        this.scenarioForm = { title: '', color: '' };
      } catch (e) {
        this.isAddingScenario = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while adding scenario stage, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    addTimelineItem(type, closePopover, data = null) {
      // console.log('addTimelineItem', this.phases);
      this.$emit('addItem', { type, payload: data });
      closePopover();
    },
    scrollToTodayMarker() {
      const container = document.querySelectorAll('.milestones-container')[0];
      const todayMarker = document.querySelectorAll('.todayMarker')[0];
      const bodyRect = container.getBoundingClientRect();
      const markerRect = todayMarker.getBoundingClientRect();
      const offsetVal = markerRect.right - bodyRect.left;

      container.scrollLeft += offsetVal;
    },
    getPhaseDates() {
      const startDates = this.phases.map((phase) => this.$moment(phase.from));
      const endDates = this.phases.map((phase) => this.$moment(phase.to));
      return {
        minPhaseDate: this.$moment.min(startDates),
        maxPhaseDate: this.$moment.max(endDates),
      };
    },
    getMilestoneDates() {
      const dates = this.displayedMilestones.map((m) => this.$moment(m.date));

      return {
        minDaysDate: this.$moment.min(dates),
        maxDaysDate: this.$moment.max(dates),
      };
    },
    handleChange(s) {
      this.currentView = '';
      this.currentView = s;
    },
    onScenarioSelect(scenarioIndex) {
      this.scenariosData.forEach((scenario, index) => {
        scenario.isActive = scenarioIndex === index;
      });
      if (scenarioIndex !== null) {
        this.filters.scenario = this.scenariosData[scenarioIndex].id;
      }
    },
    clearScenarioFilter() {
      this.filters.scenario = 'ALL';
      this.onScenarioSelect(null);
    },
    onFullscreenClick() {
      const timelineAreaEl = this.$refs['timelineArea'].$el;
      this.openFullscreen(timelineAreaEl);
    },
    openFullscreen(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    setMode({ drag, zoom }) {
      this.isDragMode = drag;
      this.isZoomMode = zoom;
    },
    setupZoomAndPan() {
      const container = this.$refs['timeline'].$el;
      const instance = renderer({
        scaleSensitivity: 20,
        minScale: 0.1,
        maxScale: 30,
        element: container,
      });
      container.addEventListener('wheel', (event) => {
        // !event.ctrlKey
        if (!this.isZoomMode) {
          return;
        }
        event.preventDefault();
        instance.zoom({
          deltaScale: Math.sign(event.deltaY) > 0 ? -1 : 1,
          x: event.pageX,
          y: event.pageY,
        });
      });
      container.addEventListener('dblclick', () => {
        instance.panTo({
          originX: 0,
          originY: 0,
          scale: 1,
        });
      });
      container.addEventListener('mousedown', () => {
        this.isMouseDown = true;
      });
      container.addEventListener('mouseup', () => {
        this.isMouseDown = false;
      });
      container.addEventListener('mouseleave', () => {
        this.isMouseDown = false;
      });
      container.addEventListener('mousemove', (event) => {
        if (!this.isMouseDown || !this.isDragMode) {
          return;
        }
        event.preventDefault();
        instance.panBy({
          originX: event.movementX,
          originY: event.movementY,
        });
      });
    },
    async setScenariosData() {
      this.scenariosData = this.scenarios.map((scenario) => ({
        ...scenario,
        isActive: scenario.isActive || false,
      }));
    },
    getMilestoneHeight(index, milestones) {
      const milestoneIndices = milestones.map((milestone) => milestone.index);
      return 200 + milestoneIndices.indexOf(index) * 130;
    },
    onMilestoneEditClick(milestone, onClose) {
      onClose();
      this.$emit('editMilestone', { ...milestone });
    },
    onMilestoneDelete(milestone, onClose) {
      onClose();
      this.$emit('editMilestone', { ...milestone, isDeleted: true });
    },
    setAsAcheived(milestone) {
      this.$emit('setAchieved', { ...milestone });
    },
    setAsInProgress(milestone) {
      this.$emit('setInProgress', { ...milestone });
    },
    async onDeleteScenario({ scenario }) {
      const scenarioIndex = this.scenarios.findIndex(
        (_scenario) => _scenario.id === scenario.id
      );
      // remove item from store
      // update isDeleted
      // if it fails, put item back
      this.deleteScenario(scenarioIndex);

      try {
        await updateScenario({
          id: scenario.id,
          set: {
            ...this.scenario,
            isDeleted: true,
          },
        });
        this.setScenariosData();
      } catch (error) {
        this.replaceScenario({
          scenario,
          index: scenarioIndex,
          deleteCount: 0,
        });
        this.$toast({
          title: 'An error occurred.',
          description: `Error while adding scenario stage, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.milestones {
  &:fullscreen {
    flex: 1;
  }
}

.scenario {
  position: relative;

  &:hover {
    .scenario__delete {
      display: block;
    }
  }
}
</style>
